import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import FlexibleFields from '../components/FlexibleFields'

export const query = graphql`
    query($id: String!, $categories: [String!]) {
        wpPage (id: { eq: $id }) {
            title
            seo {
                canonical
                cornerstone
                focuskw
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphType
                opengraphUrl
                title
                twitterDescription
                twitterTitle
            }
            flexibleContent {
                content {
                    __typename
                    ...on WpPage_Flexiblecontent_Content_About {
                        aboutType
                        scaleMomentum {
                            powerUp {
                                copy
                            }
                            powerUpCopy
                            teamUp {
                                copy
                            }
                            teamUpCopy
                        }
                        powerupTeamup {
                            powerupCopy
                            teamupCopy
                        }
                        proprietaryTechnology {
                            copy
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_CareerDetails {
                        backgroundTextLabel
                    }
                    ... on WpPage_Flexiblecontent_Content_CareerForm {
                        title
                    }
                    ... on WpPage_Flexiblecontent_Content_CareerHeader {
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_CareersArchive {
                        careerArchiveType
                    }
                    ... on WpPage_Flexiblecontent_Content_Column {
                        heading
                        content
                        position
                    }
                    ... on WpPage_Flexiblecontent_Content_Contact {
                        contactType
                        title
                        getInTouchContent {
                            title
                            titleSize
                            hideDiagonalSlice
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            button {
                                label
                                link
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_CtaText {
                        title
                        content
                        backgroundText {
                            line
                        }
                        flip
                    }
                    ... on WpPage_Flexiblecontent_Content_GameDetails {
                        title
                        headshot {
                            sourceUrl
                        }
                        quote
                        name
                        showLower
                        lowerTitle
                        lowerStatistic
                        lowerLabel
                        lowerImage {
                            sourceUrl
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_GameHeader {
                        foreground {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_GameDetails {
                        title
                        headshot {
                            sourceUrl
                        }
                        quote
                        name
                        showLower
                        lowerTitle
                        lowerStatistic
                        lowerLabel
                        lowerImage {
                            sourceUrl
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_GameResults {
                        results {
                            thumbnail {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            content {
                                mediaItemUrl
                                sourceUrl
                            }
                            statistic
                            unit
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_GamesArchive {
                        gamesArchiveType,
                        sliderContent {
                            game {
                                ... on WpGame {
                                    title
                                    slug
                                    platforms {
                                        nodes {
                                            slug
                                            platformFields {
                                                icon {
                                                    sourceUrl
                                                }
                                            }
                                        }
                                    }
                                    gameFields {
                                        thumbnail {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        videoThumbnail {
                                            sourceUrl
                                        }
                                        video {
                                            mediaItemUrl
                                        }
                                        legals
                                    }
                                }
                            }
                            bigText
                            smallText
                            foreground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            midground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                        featuredContent {
                            game {
                                ... on WpGame {
                                    title
                                    slug
                                    platforms {
                                        nodes {
                                            slug
                                            platformFields {
                                                icon {
                                                    sourceUrl
                                                }
                                            }
                                        }
                                    }
                                    gameFields {
                                        thumbnail {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        videoThumbnail {
                                            sourceUrl
                                        }	
                                        video {
                                            mediaItemUrl
                                        }
                                        logo {
                                            sourceUrl
                                            mediaDetails {
                                                sizes {
                                                    name
                                                    sourceUrl
                                                }
                                            }
                                        }
                                        legals
                                    }
                                }
                            }
                            foreground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            midground {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_GlassdoorReviews {
                        reviews {
                            review
                            rating
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_Hero {
                        title
                        backgroundType
                        background {
                            sourceUrl
                            mediaDetails {
                                sizes {
                                    name
                                    sourceUrl
                                }
                            }
                        }
                        tagline
                        social
                    }
                    ... on WpPage_Flexiblecontent_Content_Locations {
                        title
                    }
                    ... on WpPage_Flexiblecontent_Content_NewsHeader {
                        title
                        author
                        published
                    }
                    ... on WpPage_Flexiblecontent_Content_OurOfferings {
                        offerings {
                            title
                            tagline
                            description
                            powerUp
                            teamUp
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_Partners {
                        partnersType
                    }
                    ... on WpPage_Flexiblecontent_Content_PostArchive {
                        postArchiveType
                        imageRowsContent {
                            post {
                                ... on WpPost {
                                    title
                                    content
                                    slug
                                    postFields {
                                        offSitePost
                                        postUrl
                                        thumbnail {
                                            mediaItemUrl
                                        }
                                    }
                                }
                            }
                        }
                        featuredContent {
                            post {
                                ... on WpPost {
                                    title
                                    slug
                                    postFields {
                                        offSitePost
                                        postUrl
                                        thumbnail {
                                            mediaItemUrl
                                        }
                                    }
                                }
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            highlights {
                                image {
                                    sourceUrl
                                }
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_ProprietaryTechnology {
                        technologies {
                            name
                            title
                            tagline
                            copy
                            image {
                                sourceUrl
                            }
                            background {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            theme
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_StaticImage {
                        image {
                            sourceUrl
                        }
                        subtitle
                        hasCredit
                        credit
                    }
                    ... on WpPage_Flexiblecontent_Content_Team {
                        teamMembers {
                            headshot {
                                sourceUrl
                            }
                            name
                            jobTitle
                            description
                            socialLinks {
                                linkedin {
                                    url
                                }
                                facebook {
                                    url
                                }
                                twitter {
                                    url
                                }
                                instagram {
                                    url
                                }
                                youtube {
                                    url
                                }
                            }
                        }
                    }
                    ... on WpPage_Flexiblecontent_Content_Testimonials {
                        testimonials {
                            headshot {
                                sourceUrl
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            name
                            jobTitle
                            testimonial
                        }
                        theme
                        title
                        description
                    }
                    ... on WpPage_Flexiblecontent_Content_Video {
                        title
                        game {
                            ... on WpGame {
                                title
                                slug
                                platforms {
                                    nodes {
                                        slug
                                        platformFields {
                                            icon {
                                                sourceUrl
                                            }
                                        }
                                    }
                                }
                                gameFields {
                                    thumbnail {
                                        sourceUrl
                                        mediaDetails {
                                            sizes {
                                                name
                                                sourceUrl
                                            }
                                        }
                                    }	
                                    videoThumbnail {
                                        sourceUrl
                                    }
                                    video {
                                        mediaItemUrl
                                    }
                                    legals
                                    links {
                                        amazonAppStore
                                        googlePlay
                                        iosAppStore
                                        macAppStore
                                        microsoftStore
                                        samsungGalaxyStore
                                        steam
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        archivePosts: allWpPost (
            limit: 1000
            sort: { fields: date, order: DESC }
            filter: { categories: { nodes: { elemMatch: { slug: { in: $categories }}}}}
        ) {
            nodes {
                title
                content
                slug
                date
                postFields {
                    offSitePost
                    postUrl
                    thumbnail {
                        mediaItemUrl
                    }
                }
            }
        }
    }
`

export default ({
    data,
    pageContext: {
		categories,
	},
    ...props
}) => {
    const page = data.wpPage

    const category = categories.length > 1 ? false : categories[0]

    return (
        <Layout>
            <SEO title="News &amp; Blog" />
            <FlexibleFields
                data={ page }
                content={ page?.flexibleContent?.content ?? [] }
                archivePosts={ data?.archivePosts?.nodes ?? [] }
                {...{ category, ...props }}
            />
        </Layout>
    )
}
